import {IComponentOptions, IController, IScope, ITimeoutService} from "angular";
import * as template from "./try-on-picture.component.html";
import {mainModule} from "../app";
import {Scenario} from "vtom-api-typescript-client";

class Controller implements IController {
    scenario: Scenario;
    imageType: string;

    imageUrl: string;

    constructor(private readonly $timeout: ITimeoutService) {
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.scenario || !this.imageType) {
            this.imageUrl = null;
            return;
        }
        if (!this.imageUrl) {
            this.updateImageUrl();
            return;
        }
        // ez-plus does not accept change of image
        // force the creation of a new instance
        this.imageUrl = null;
        this.$timeout(() => this.updateImageUrl(), 0);
    }

    private updateImageUrl(): void {
        this.imageUrl =  `./api/scenarios/${this.scenario.id}/files/${this.imageType}`;
        console.log('Image url updated!', this.imageUrl);
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        scenario: '<',
        imageType: '<',
    }
};

mainModule.component('tryOnPicture', component);
